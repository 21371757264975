<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Serviços </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/">Início</a></li>
                <li class="breadcrumb-item active" aria-current="page">Serviços</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!--Shape End-->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-12">
        <div class="features mt-5">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-edit-alt h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Design & Development</h5>
            <p class="text-muted mb-0">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean
              elementum semper.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-vector-square h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Management & Marketing</h5>
            <p class="text-muted mb-0">Allegedly, a Latin scholar established the origin of the text by
              established compiling unusual word.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-file-search-alt h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Stratagy & Research</h5>
            <p class="text-muted mb-0">It seems that only fragments of the original text remain in the Lorem the
              original Ipsum texts used today.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-airplay h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Easy To Use</h5>
            <p class="text-muted mb-0">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam
              eleifend aenean elementum semper.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Daily Reports</h5>
            <p class="text-muted mb-0">Allegedly, a Latin scholar established the origin of the established text
              by compiling unusual word.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-clock h1 text-primary"></i>
          </div>

          <div class="content mt-4">
            <h5>Real Time Zone</h5>
            <p class="text-muted mb-0">It seems that only fragments of the original text remain in only fragments
              the Lorem Ipsum texts used today.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

</section>
<!--end section-->
<!-- Feature Start -->

<!-- Project Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Latest Projects</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
          <div class="card-body p-0">
            <img src="../assets/images/work/1.jpg" class="img-fluid rounded" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <a href="portfolio-detail-one.html" class="title text-white d-block fw-bold">Shifting
                Perspective</a>
              <small class="text-light">Studio</small>
            </div>
            <div class="client">
              <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
              <small class="text-light date"><i class="muil uil-calendar-alt"></i> 13th August, 2019</small>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
          <div class="card-body p-0">
            <img src="../assets/images/work/2.jpg" class="img-fluid rounded" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <a href="portfolio-detail-one.html" class="title text-white d-block fw-bold">Colors Magazine</a>
              <small class="text-light">Web Design</small>
            </div>
            <div class="client">
              <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
              <small class="text-light date"><i class="muil uil-calendar-alt"></i> 13th August, 2019</small>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
          <div class="card-body p-0">
            <img src="../assets/images/work/3.jpg" class="img-fluid rounded" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <a href="portfolio-detail-one.html" class="title text-white d-block fw-bold">Spa Cosmetics</a>
              <small class="text-light">Developing</small>
            </div>
            <div class="client">
              <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
              <small class="text-light date"><i class="muil uil-calendar-alt"></i> 13th August, 2019</small>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card work-container work-modern position-relative overflow-hidden shadow rounded border-0">
          <div class="card-body p-0">
            <img src="../assets/images/work/4.jpg" class="img-fluid rounded" alt="work-image">
            <div class="overlay-work bg-dark"></div>
            <div class="content">
              <a href="portfolio-detail-one.html" class="title text-white d-block fw-bold">Riser Coffee</a>
              <small class="text-light">Branding</small>
            </div>
            <div class="client">
              <small class="text-light user d-block"><i class="uil uil-user"></i> Calvin Carlo</small>
              <small class="text-light date"><i class="muil uil-calendar-alt"></i> 13th August, 2019</small>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">See everything about your employee at one place.</h4>
          <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span>
            that can provide everything you need to generate
            awareness, drive traffic, connect.</p>

          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2">Get Started Now</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2">Free Trial</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Project End -->
