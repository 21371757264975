<!-- Navbar Start -->
<header id="topnav" class="defaultscroll sticky">
  <div class="container">
    <!-- Logo container-->
    <a class="logo" routerLink="/">
      <img src="../assets/images/logo-dark.png" height="24" class="logo-light-mode" alt="">
      <img src="../assets/images/logo-light.png" height="24" class="logo-dark-mode" alt="">
    </a>
    <div class="buy-button">
      <a href="#" class="btn btn-primary">Fazer Login</a>
    </div>
    <!--end login button-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" id="isToggle" onclick="toggleMenu()">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu">
        <li><a routerLink="/" class="sub-menu-item">Início</a></li>
        <li><a routerLink="/empresa" class="sub-menu-item">Empresa</a></li>
        <li><a class="sub-menu-item">Ajuda</a></li>
        <li><a routerLink="/fale-conosco" class="sub-menu-item">Fale Conosco</a></li>
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a href="#" class="btn btn-primary">Fazer Login</a>
      </div>
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
