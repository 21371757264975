<!-- Hero Start -->
<section class="bg-half-170 d-table w-100">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-4 col-md-5 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="bg-white shadow rounded position-relative overflow-hidden">
          <div class="text-center">
            <ul class="nav nav-pills nav-justified flex-sm-row mb-0" id="pills-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link py-2 active rounded-0" id="User-login" data-bs-toggle="pill" href="#user" role="tab"
                  aria-controls="user" aria-selected="false">
                  <div class="text-center py-2">
                    <h6 class="mb-0">Usuário</h6>
                  </div>
                </a>
                <!--end nav link-->
              </li>
              <!--end nav item-->

              <li class="nav-item">
                <a class="nav-link py-2 rounded-0" id="Driver-login" data-bs-toggle="pill" href="#driver" role="tab"
                  aria-controls="driver" aria-selected="false">
                  <div class="text-center py-2">
                    <h6 class="mb-0">Motorista</h6>
                  </div>
                </a>
                <!--end nav link-->
              </li>
              <!--end nav item-->
            </ul>
          </div>
          <div class="tab-content" id="pills-tabContent">
            <div class="card border-0 tab-pane fade show active" id="user" role="tabpanel" aria-labelledby="User-login">
              <form class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Nome Completo <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="user" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="Nome Completo" name="s" required="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Seu email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="mail" class="fea icon-sm icons"></i>
                        <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="key" class="fea icon-sm icons"></i>
                        <input type="password" class="form-control ps-5" placeholder="Senha" required="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="user-check">
                        <label class="form-check-label" for="user-check">Eu aceito <a href="#"
                            class="text-primary">Termos
                            e Condições</a></label>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="d-grid">
                      <button class="btn btn-primary">Cadastrar</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
              <!--end form-->
            </div>
            <!--end teb pane-->

            <div class="card border-0 tab-pane fade" id="driver" role="tabpanel" aria-labelledby="Driver-login">
              <form class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Nome Completo <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="user" class="fea icon-sm icons"></i>
                        <input type="text" class="form-control ps-5" placeholder="Nome Completo" name="s" required="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Seu Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="mail" class="fea icon-sm icons"></i>
                        <input type="email" class="form-control ps-5" placeholder="Email" name="email" required="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="form-label">Senha <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i data-feather="key" class="fea icon-sm icons"></i>
                        <input type="password" class="form-control ps-5" placeholder="Senha" required="">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="mb-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="driver-check">
                        <label class="form-check-label" for="driver-check">Eu aceito <a href="#"
                            class="text-primary">Termos e Condições</a></label>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-md-12">
                    <div class="d-grid">
                      <button class="btn btn-primary">Cadastrar</button>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
              <!--end form-->
            </div>
            <!--end teb pane-->
          </div>
          <!--end tab content-->
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 offset-lg-1 col-md-7 order-1 order-md-2">
        <div class="title-heading mt-4">
          <h1 class="display-4 fw-normal mb-3">Preço imbatível e serviços especializados</h1>
          <p class="para-desc text-muted">
            Lance sua campanha e beneficie-se de nossa experiência em projetar e gerenciar a página de bootstrap v5 html
            centrada em conversão.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Features Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Encontre seus melhores passeios</h4>
          <p class="text-muted para-desc mx-auto mb-0">Comece a trabalhar com <span
              class="text-primary fw-bold">RentVan</span>
            que pode fornecer tudo que você precisa para gerar consciência, direcione o tráfego, conecte-se.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="ride-image position-relative">
            <img src="../assets/images/riding/01.png" class="avatar avatar-ex-large shadow" alt="">
          </div>
          <div class="content mt-5 pt-3">
            <h4 class="title-2"><a href="javascript:void(0)" class="text-dark">Selecione a Van</a></h4>
            <p class="text-muted mb-0">Texto fictício é o texto usado na indústria editorial ou por web designers para
              ocupar o espaço</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="ride-image">
            <img src="../assets/images/riding/02.png" class="avatar avatar-ex-large shadow" alt="">
          </div>
          <div class="content mt-5 pt-3">
            <h4 class="title-2"><a href="javascript:void(0)" class="text-dark">Decida o seu destino</a></h4>
            <p class="text-muted mb-0">
              Texto fictício é o texto usado na indústria editorial ou por web designers para
              ocupar o espaço</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="ride-image">
            <img src="../assets/images/riding/03.png" class="avatar avatar-ex-large shadow" alt="">
          </div>
          <div class="content mt-5 pt-3">
            <h4 class="title-2"><a href="javascript:void(0)" class="text-dark">Contato com o revendedor</a></h4>
            <p class="text-muted mb-0">Texto fictício é o texto usado na indústria editorial ou por web designers para
              ocupar o espaço</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Features End -->

<!-- Ride Request Start -->
<section class="section">
  <div class="container">
    <div class="card bg-light rounded border-0 overflow-hidden">
      <div class="row g-0 align-items-center">
        <div class="col-md-6 mt-2 mt-sm-0 order-2 order-md-1">
          <div class="card-body section-title p-lg-5">
            <h2 class="mb-4 fw-normal">Solicite uma viagem agora</h2>
            <form>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Escolha o ponto de partida</label>
                    <div class="form-icon position-relative">
                      <i data-feather="map-pin" class="fea icon-sm icons text-success"></i>
                      <input name="name" id="location1" type="text" class="form-control ps-5"
                        placeholder="Escolha o ponto de partida ...">
                    </div>
                  </div>
                </div>
                <!--end col-->

                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Escolha o seu destino</label>
                    <div class="form-icon position-relative">
                      <i data-feather="map-pin" class="fea icon-sm icons text-danger"></i>
                      <input name="name" id="location2" type="text" class="form-control ps-5"
                        placeholder="Escolha o seu destino...">
                    </div>
                  </div>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
              <a href="javascript:void(0)" class="btn btn-primary">Request Now <i class="uil uil-angle-right-b"></i></a>
            </form>
            <!--end form-->
          </div>
        </div>
        <!--end col-->

        <div class="col-md-6 order-1 order-md-2">
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5972.726864468592!2d-46.69981694785586!3d-23.62337954592048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf636e54dd93f091e!2sMorumbi%20Shopping!5e0!3m2!1spt-BR!2sbr!4v1620180948809!5m2!1spt-BR!2sbr"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Também fornecemos mais serviços</h4>
          <p class="text-muted para-desc mx-auto mb-0">Comece a trabalhar com <span
              class="text-primary fw-bold">RentVan</span> que pode fornecer tudo que você precisa para gerar
            consciência,
            direcione o tráfego, conecte-se.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="../assets/images/icon/car.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Transporte</h4>
            <p class="text-muted mb-0">Texto fictício é o texto usado na indústria
              editorial ou por web designers para ocupar o espaço.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="../assets/images/icon/food-and-restaurant.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Comida</h4>
            <p class="text-muted mb-0">Supostamente, um estudioso latino estabeleceu a origem do texto estabelecido por
              compilar palavra incomum.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="../assets/images/icon/fast.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Entrega</h4>
            <p class="text-muted mb-0">Supostamente, um estudioso latino estabeleceu a origem do texto estabelecido por
              compilar palavra incomum.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="../assets/images/icon/card.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Pagamentos</h4>
            <p class="text-muted mb-0">Supostamente, um estudioso latino estabeleceu a origem do texto estabelecido por
              compilar palavra incomum.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="../assets/images/icon/movie-tickets.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Ingressos</h4>
            <p class="text-muted mb-0">Supostamente, um estudioso latino estabeleceu a origem do
              texto estabelecido por compilar palavra incomum.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="../assets/images/icon/hotel.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Hotéis</h4>
            <p class="text-muted mb-0">Supostamente, um estudioso latino estabeleceu a origem do
              texto estabelecido por compilar palavra incomum.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--remoção slide -->

</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Ride Request End -->

<!-- App Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Tem pergunta ?</h4>
          <p class="text-muted para-desc mx-auto mb-0">Comece a trabalhar com <span
              class="text-primary fw-bold">RentVan</span> que pode fornecer tudo que você precisa para gerar
            consciência,
            direcione o tráfego, conecte-se.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="row">
        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="d-flex">
            <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
            <div class="flex-1">
              <h5 class="mt-0">How our <span class="text-primary">RentVan</span> </h5>
              <p class="answer text-muted mb-0">Devido ao seu amplo uso como texto de preenchimento para layouts, a não
                legibilidade é
                de grande importância: a percepção humana é ajustada para reconhecer certos padrões e repetições em
                textos.
              </p>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="d-flex">
            <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
            <div class="flex-1">
              <h5 class="mt-0"> Qual é o principal processo de abertura de conta?</h5>
              <p class="answer text-muted mb-0">Se a distribuição de letras e 'palavras' for aleatória, o leitor irá
                não se distraia de fazer um julgamento neutro sobre o impacto visual</p>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="d-flex">
            <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
            <div class="flex-1">
              <h5 class="mt-0"> Como fazer entrada de dados ilimitada?</h5>
              <p class="answer text-muted mb-0">
                Além disso, é vantajoso quando o texto fictício é relativamente realista
                para que a impressão do layout da publicação final não seja comprometida.</p>
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-6 col-12 mt-4 pt-2">
          <div class="d-flex">
            <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
            <div class="flex-1">
              <h5 class="mt-0"> É <span class="text-primary">RentVan</span> mais seguro para usar com minha conta?</h5>
              <p class="answer text-muted mb-0">O texto fictício mais conhecido é o 'Lorem Ipsum', que se diz
                originaram-se no século XVI. Lorem Ipsum é composto em uma língua pseudo-latina que mais ou menos
                corresponde ao latim "próprio".</p>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end row-->

    <div class="row mt-5 pt-lg-3 justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Baixe nosso aplicativo agora!</h4>
          <p class="text-muted para-desc mx-auto">Comece a trabalhar com <span
              class="text-primary fw-bold">RentVan</span>
            que pode fornecer tudo o que você precisa para gerar reconhecimento, direcionar o tráfego e se conectar.</p>
          <div class="mt-4 pt-2">
            <div class="text-center subcribe-form">
              <form class="mb-4">
                <input name="number" id="number" type="number" class="form-control rounded-pill"
                  placeholder="Número do seu celular. :" required="" aria-describedby="cellnumber">
                <button class="btn btn-pills btn-primary submitBnt" type="submit" id="cellnumber">Enviar link do
                  aplicativo</button>
              </form>
              <!--end form-->
            </div>

            <p class="text-muted">Or</p>

            <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a>
            <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play
              Store</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end contanainer-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- App End -->
