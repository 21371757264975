<!-- Footer Start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
        <a href="#" class="logo-footer">
          <img src="../assets/images/logo-light.png" height="24" alt="">
        </a>
        <p class="mt-4">Comece a trabalhar com RentVan, que pode fornecer tudo o que você precisa para gerar
          conhecimento,
          direcionar tráfego e conectar-se.</p>
        <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i data-feather="facebook"
                class="fea icon-sm fea-social"></i></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i data-feather="instagram"
                class="fea icon-sm fea-social"></i></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i data-feather="twitter"
                class="fea icon-sm fea-social"></i></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded"><i data-feather="linkedin"
                class="fea icon-sm fea-social"></i></a></li>
        </ul>
        <!--end icon-->
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h5 class="text-light footer-head">Empresa</h5>
        <ul class="list-unstyled footer-list mt-4">
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i> Rent Van</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i> Serviços</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i> Equipe</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i> Carreira</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i> Blog</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i> Login</a>
          </li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h5 class="text-light footer-head">Links Úteis</h5>
        <ul class="list-unstyled footer-list mt-4">
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>Termos de Serviços</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>Política de Privacidade</a></li>
          <li><a href="#" class="text-foot"><i class="uil uil-angle-right-b me-1"></i>Ajuda</a></li>
        </ul>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <h5 class="text-light footer-head">Boletim de Notícias</h5>
        <p class="mt-4">Inscreva-se e receba as últimas dicas por e-mail.</p>
        <form>
          <div class="row">
            <div class="col-lg-12">
              <div class="foot-subscribe mb-3">
                <label class="form-label">Escreva seu email <span class="text-danger">*</span></label>
                <div class="form-icon position-relative">
                  <i data-feather="mail" class="fea icon-sm icons"></i>
                  <input type="email" name="email" id="emailsubscribe" class="form-control ps-5 rounded"
                    placeholder="Seu email : " required>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="d-grid">
                <input type="submit" id="submitsubscribe" name="send" class="btn btn-soft-primary" value="Se inscrever">
              </div>
            </div>
          </div>
        </form>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
<!--end footer-->
<footer class="footer footer-bar">
  <div class="container text-center">
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="text-sm-start">
          <p class="mb-0">© <script>
              document.write(new Date().getFullYear())

            </script> RentVan Todos Direitos Reservados<a href="#" target="_blank" class="text-reset"></a>.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <ul class="list-unstyled text-sm-end mb-0">
          <li class="list-inline-item"><a href="javascript:void(0)"><img src="../assets/images/payments/american-ex.png"
                class="avatar avatar-ex-sm" title="American Express" alt=""></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)"><img src="../assets/images/payments/discover.png"
                class="avatar avatar-ex-sm" title="Discover" alt=""></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)"><img src="../assets/images/payments/master-card.png"
                class="avatar avatar-ex-sm" title="Master Card" alt=""></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)"><img src="../assets/images/payments/paypal.png"
                class="avatar avatar-ex-sm" title="Paypal" alt=""></a></li>
          <li class="list-inline-item"><a href="javascript:void(0)"><img src="../assets/images/payments/visa.png"
                class="avatar avatar-ex-sm" title="Visa" alt=""></a></li>
        </ul>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</footer>
<!--end footer-->
<!-- Footer End -->
